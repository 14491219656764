import './App.css';
import Swiper from "./components/Swiper/Swiper";
import AboutUs from "./views/AboutUs/AboutUs";
import Missions from "./views/Missions/Missions";
import Programs from "./views/Programs/Programs";
import FAQ from "./views/FAQ/FAQ";
import Donation from "./views/Donation/Donation";
import Navbar from "./components/Navbar/Navbar";

function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <div style={{marginTop: 60}}>
          <Swiper/>
           <AboutUs/>
          <Missions />
          <Programs />
          <FAQ />
          <Donation />
      </div>
        <footer className="footer">
            © copyright 2022 - by ICI Armenia
        </footer>
    </div>
  );
}

export default App;
