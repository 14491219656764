import React from 'react';
import useWindowSize from "../../hooks/useWindowSize.hook";
import './Section.css';

export default function Section(information){
    const {position} = information;
    const {width} = useWindowSize();


    return(
        <div className="section">
            <div className="section-block">
                <p className="title" id={position.id}>{position.title}</p>
                <div className={`description-block ${width > 1278 ? position.position === 'left' ? 'left' : 'right' : ''}`}>
                    <img src={position.img} alt={`img-${position.title}`}/>
                    <div className="description-block-div">
                        <div  dangerouslySetInnerHTML={{ __html: position.description }} />
                        {position.id === 'donate' &&  <button className="donate" style={{marginTop: 30}}>
                            Նվիրաբերել
                        </button> }
                    </div>

                </div>
            </div>
        </div>
    )
}