import React from "react";
import Faq from "react-faq-component";

import './Faq.css';

const data = {
    title: "",
    rows: [
        {
            title: "Ովքե՞ր են հանդիսանում մեր ծրագրերի շահառուներ։",
            content: `Մեր ծրագրերի շահառուներ են հանդիսանում հիմնականում սոցիալապես խոցելի խմբերը՝ սահմանային տարածքների բնակիչները, հայրենադարձները, անապահով ընտանիքները, սեռական խտրականությանն ենթարկվող անձիք և այլ խոցելի խմբերի ներկայացուցիչներ։`,
        },
        {
            title: "Ի՞նչ տեսքով ենք աջակցում ծրագրերի շահառուներին։",
            content:
                "Հիմնական աջակցությունն իրականացվում է շահառուի իրազեկության մակարդակը բարձրացնելու տեսքով։ Օրինակ՝ խտրականությանն ենթարկվող անձանց բացատրվում են օրենսդրությամբ սահմանված իրենց իրավունքները; ինքնազբաղված անձանց համար անց են կացվում ձեռներեցության դասընթացներ; անբարենպաստ շրջակա միջավայրում բնակվողների համար անց են կացվում բնապահպանական դասընթացներ։",
        },
        {
            title: "Իրականացնու՞մ եք արդյոք դասընթացներից բացի այլ աջակցություն։",
            content: `Այո, առանձին դեպքերում իրականացնում ենք ուղղակի իրավական օգնություն, ուղղակի խորհրդատվություն ձեռներեցության վերաբերյալ և այլ տեսակի ուղղակի օգնություն։`,
        },
        {
            title: "Ինչպե՞ս կարող ենք համոզվել, որ հատկացրած գումարները ծախսվում են՝ ըստ նպատակային նշանակության։",
            content: "Բոլոր հատկացվող գումարների մասին տեղեկատվությունը արտացոլվում է մեր կայքում, ինչպես նաև՝ իրականացվող ծրագրերի և դրանց շահառուների վերաբերյալ տեղեկատվությունը, որը հաստատվում է համապատասխան տեսաձայնագրություններով և այլ նյութերով։",
        },
    ],
};

const styles = {
    width: "90%",
    margin: "0 auto",
    // bgColor: 'white',
    titleTextColor: "blue",
    rowTitleColor: "grey",
    textAlign: 'left',
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true
};

export default function FAQ() {

    return (
        <div className="content" style={{textAlign: 'left'}}>
            <p className="title" id='faq'>Հարց և Պատասխան</p>
            <p style={{fontSize: 16}}>Մենք տրամադրում ենք համապարփակ տեղեկատվություն մեր գործունեության վերաբերյալ՝ ոչ միայն հաշվետվություններ հրապարակելու միջոցով, այլ նաև անհատականապես պատասխանելով այդ գործունեությանը վերաբերվող հարցերին։</p>
            <p style={{ marginBottom: 20, fontSize: 16}}>Դրա հետ մեկ տեղ, մենք հրապարակում ենք առավել հաճախակի տրվող հարցերի պատասխանները։</p>
            <Faq
                data={data}
                styles={styles}
                config={config}
            />
            <p style={{marginTop: 20, fontSize: 16}}>Ցանկացած հարցի առկայության պարագայում կարող եք դիմել մեզ անհատապես։</p>
        </div>
    );
}