import React from 'react';
import Section from "../../components/Section/Section";
import {pageConstants} from "../../constants/pageConstants";

export default function Missions(){
    const {description, position, img, title, id} = pageConstants.missions;
    const information = {
        description,
        position,
        img,
        title,
        id,
    }
    return(
        <div>
            <Section position={information} />
        </div>
    )
}