import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperImage from '../../assets/home-slider-2.jpg';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import "./styles.css";

// import required modules
import { Navigation } from "swiper";

export default function App() {
    return (
        <>
            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                <SwiperSlide><img src={SwiperImage} alt='swiper-1'/></SwiperSlide>
                <SwiperSlide><img src={SwiperImage} alt='swiper-2'/></SwiperSlide>
                <SwiperSlide><img src={SwiperImage} alt='swiper-3'/></SwiperSlide>
                <SwiperSlide><img src={SwiperImage} alt='swiper-4'/></SwiperSlide>
                <SwiperSlide><img src={SwiperImage} alt='swiper-5'/></SwiperSlide>
            </Swiper>
        </>
    );
}
