import React from 'react';
import {pageConstants} from "../../constants/pageConstants";
import Section from "../../components/Section/Section";

export default function Donation(){
    const {description, position, img, title, id} = pageConstants.donation;
    const information = {
        description,
        position,
        img,
        title,
        id,
    }
    return(
        <div>
            <Section position={information}/>
        </div>
    )
}