import React from 'react';
import './Navbar.css';
import Hamburger from "../Hamburger/Hamburger";
import useWindowSize from "../../hooks/useWindowSize.hook";
export default function Navbar(){
    const {width} = useWindowSize();

    const scrollToPosition = (position) => {
        window.scrollTo({
            top: document.getElementById(position)?.offsetTop - 80,
            behavior: 'smooth',
        })
    };

    return(
        <nav className="navbar">
            {width > 1279 ? (
                <ul className="nav content">
                    <li onClick={()=>scrollToPosition('aboutUs')}>Մեր Մասին</li>
                    <li onClick={()=>scrollToPosition('mission')}>Մեր Առաքելությունը</li>
                    <li onClick={()=>scrollToPosition('programs')}>Մեր Ծրագրերը</li>
                    <li onClick={()=>scrollToPosition('faq')}>Հարց և Պատասխան</li>
                    <li onClick={()=>scrollToPosition('donate')} className='donate'>Նվիրաբերել</li>
                </ul>
            ): <Hamburger />}


        </nav>
    )
}