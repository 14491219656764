import React from 'react';
import './Hamburger.css';

export default function Hamburger(){
    const scrollToPosition = (position) => {
        window.scrollTo({
            top: document.getElementById(position)?.offsetTop - 80,
            behavior: 'smooth',
        })
        document.getElementById('box').checked = !document.getElementById('box').checked;
    };
    return(
        <div className="container">
            <input type="checkbox" name="" id="box" className="check" />
                <ul className="menu-items">
                    <li onClick={()=>scrollToPosition('aboutUs')}>Մեր Մասին</li>
                    <li onClick={()=>scrollToPosition('mission')}>Մեր Առաքելությունը</li>
                    <li onClick={()=>scrollToPosition('programs')}>Մեր Ծրագրերը</li>
                    <li onClick={()=>scrollToPosition('faq')}>Հարց և Պատասխան</li>
                    <li onClick={()=>scrollToPosition('donate')} className='donate'>Նվիրաբերել</li>
                </ul>
                <div className="ham-menu">
                    <span className="line line1"></span>
                    <span className="line line2"></span>
                    <span className="line line3"></span>
                </div>
        </div>
    )
}