import Picture1 from '../assets/we-fight-togother.jpg';
import Picture2 from '../assets/we-care-about.jpg';
export const pageConstants = {
    aboutUs: {
        position: 'left',
        title: 'Մեր Մասին',
        img: Picture1,
        id: 'aboutUs',
        description: '<div><p>Մեր կազմակերպությունը ստեղծվել է 2019 թվականին։ Քաղաքացիական նախաձեռնությունների ինստիտուտի աշխատանքի առաջին իսկ արգասիքներն ներկայացվել են հանրությանը՝ տարբեր հիմնահարցերի փորձագիտական վերլուծության տեսքով։ Կազմակերպության գործունեության առաջին տարվա ընթացքում վերլուծության և հանրային քննարկման առարկա են դարձել իրավական և տնտեսական հիմնահարցերը։\n' +
            'Հետագայում Քաղաքացիական նախաձեռնությունների ինստիտուտը կենտրոնացել է սոցիալապես խոցելի խմբերի խոցելիության աստիճանն իջեցնելու և պաշտպանվածության մակարդակը բարձրացնելու խնդիրների լուծման վրա։ Այցելելով թիրախային տարածաշրջանները, կազմակերպության անդամները անց են կացրել բազմաթիվ քննարկումներ շահագրգիռ անձանց հետ, իրականացրել խորհրդատվական աշխատանքներ, որը թույլ է տվել ուրվագծել հետագա անելիքները և կազմել ռազմավարական ծրագրեր։\n' +
            'Հիշատակված ծրագրերն իրականացվում են կազմակերպության կողմից ստացվող միջոցների հաշվին, որ իսկ միջոցները մենք ստանում ենք աշխարհի տարբեր երկրներում բնակվող մեր հայրենակիցներից և սատարողներից։\n' +
            'Մեր կազմակերպության փորձագետները բարձր համբավ ունեն իրենց մասնագիտական ոլորտներում, օժտված են գիտական աստիճաններով և հանդիսանում են բազմաթիվ գիտական աշխատությունների հեղինակներ։</p></div>'

    },
    programs: {
        position: 'left',
        title: 'Ծրագրեր',
        img: Picture2,
        id: 'programs',
        description: '<div>Մենք իրագործում ենք իրազեկության մակարդակի բարձրացման ծրագրեր՝ մշտական հիմունքներով։ Այդ ծրագրերն ունեն, մասնավորապես, երեք հիմնական ուղղվածություններ՝\n' +
            '1․ Իրավական իրազեկման\n' +
            '2․ Ձեռնարկատիրական հմտությունների ձեռքբերման\n' +
            '3․ Բնապահպանական իրազեկման\n' +
            'Առանձին դեպքերում մենք իրականացնում ենք հատուկ հասցեական կարճաժամկետ ծրագրեր՝ օրինակ, 2020 թվականին իրակացվել է հայ սահմանապահներին և տարահանված անձանց հումանիտար օգնության ծրագիր։\n' +
            'Մեր կողմից իրականացվող մշտական ծրագրերի թիրախային տարածաշրջաններն են՝ Հայաստանի սահմանամերձ բնակավայրերը։\n' +
            'Ծրագրերի հիմնական շահառուներն են՝ սոցիալապես խոցելի խմբերի ներկայացուցիչները։</div>'

    },
    missions: {
        position: 'right',
        title: 'Մեր Առաքելությունը',
        img: Picture1,
        id: 'mission',
        description: '<div>Մեր առաքելությունը կայանում է քաղաքացիական հասարակության իրազեկության մակարդակի բարձրացման մեջ՝ այն ոլորտներում, որոնք կենսական նշանակություն ունեն այդ հասարակության ներկայացուցիչների համար։\n' +
            'Քաղաքացիական հասարակության մեր տեսլականի շրջանակներում յուրաքանչյուր քաղաքացի պետք է իրազեկված լինի իր հիմնարար իրավունքների վերաբերյալ, ի զորու լինի ապահովել իր կյանքի պատշաճ մակարդակը և որակը։\n' +
            'Եթե կյանքի պատշաճ մակարդակն ապահովելու համար հիմնական նախադրյալն է հանդիսանում անհատի և/կամ ընտանիքի ֆինանսական կայունությունը, ապա կյանքի պատշաճ որակը սկսվում է բարվոք շրջակա միջավայրից։ Այս իսկ պատճառով մեր առաքելության հիմնական բաղադրիչներն են հանդիսանում՝ իրավական, ձեռնարկատիրական և բնապահպանական ծրագրերը։</div>'

    },
    faq: {
        position: 'right',
        title: 'Հարց և Պատասխան',
        img: Picture2,
        id: 'faq',
        description: '<div>Մենք տրամադրում ենք համապարփակ տեղեկատվություն մեր գործունեության վերաբերյալ՝ ոչ միայն հաշվետվություններ հրապարակելու միջոցով, այլ նաև անհատականապես պատասխանելով այդ գործունեությանը վերաբերվող հարցերին։\n' +
            'Դրա հետ մեկ տեղ, մենք հրապարակում ենք առավել հաճախակի տրվող հարցերի պատասխանները։\n' +
            'Հարց 1․ Ովքե՞ր են հանդիսանում մեր ծրագրերի շահառուներ։\n' +
            'Պատասխան․ Մեր ծրագրերի շահառուներ են հանդիսանում հիմնականում սոցիալապես խոցելի խմբերը՝ սահմանային տարածքների բնակիչները, հայրենադարձները, անապահով ընտանիքները, սեռական խտրականությանն ենթարկվող անձիք և այլ խոցելի խմբերի ներկայացուցիչներ։\n' +
            'Հարց 2․ Ի՞նչ տեսքով ենք աջակցում ծրագրերի շահառուներին։\n' +
            'Պատասխան․ Հիմնական աջակցությունն իրականացվում է շահառուի իրազեկության մակարդակը բարձրացնելու տեսքով։ Օրինակ՝ խտրականությանն ենթարկվող անձանց բացատրվում են օրենսդրությամբ սահմանված իրենց իրավունքները; ինքնազբաղված անձանց համար անց են կացվում ձեռներեցության դասընթացներ; անբարենպաստ շրջակա միջավայրում բնակվողների համար անց են կացվում բնապահպանական դասընթացներ։\n' +
            'Հարց 3․ Իրականացնու՞մ եք արդյոք դասընթացներից բացի այլ աջակցություն։\n' +
            'Պատասխան․ Այո, առանձին դեպքերում իրականացնում ենք ուղղակի իրավական օգնություն, ուղղակի խորհրդատվություն ձեռներեցության վերաբերյալ և այլ տեսակի ուղղակի օգնություն։\n' +
            'Հարց 4․ Ինչպե՞ս կարող ենք համոզվել, որ հատկացրած գումարները ծախսվում են՝ ըստ նպատակային նշանակության։\n' +
            'Պատասխան․ Բոլոր հատկացվող գումարների մասին տեղեկատվությունը արտացոլվում է մեր կայքում, ինչպես նաև՝ իրականացվող ծրագրերի և դրանց շահառուների վերաբերյալ տեղեկատվությունը, որը հաստատվում է համապատասխան տեսաձայնագրություններով և այլ նյութերով։\n' +
            'Ցանկացած հարցի առկայության պարագայում կարող եք դիմել մեզ անհատապես։</div>'

    },
    donation: {
        position: 'left',
        title: 'Նվիրատվություն',
        img: Picture1,
        id: 'donate',
        description: '<div>Նվիրատվություն կատարելով, դուք մասնակցում եք Հայաստանի քաղաքացիական հասարակության զարգացման գործընթացում՝ այնքանով, որքանով նման զարգացմանը նպաստում է այդ հասարակության իրազեկության մակարդակի բարձրացումն այնպիսի ոլորտներում, որոնք կենսական նշանակություն ունեն յուրաքանչյուր քաղաքացու համար։\n' +
            'Բոլոր նվիրատվությունները արտացոլվում են մեր կայքում, ինչպես նաև ֆինանսական հաշվետվությունները, որոնք ցույց են տալիս, թե ինչ նպատակով և որտեղ են ծախսվել ստացված գումարները։\n' +
            'Մենք իրականացնում ենք բացարձակ թափանցիքության քաղաքականություն, գործում ենք ՄԱԿ-ի կողմից ընդունված Փողերի լվածման, հանցավոր գործունեությունից եկամուտների և ահաբեկչության ֆինանսավորման դեմ պայքարի գլոբլա ծրագրի շրջանակներում և Տնտեսական համագործակցության և զարգացման կազմակերպության համապատասխան փաստաթղթերով սահմանված դրույթներին համապատասխան։</div>'

    }
}